import type { NextPage } from "next"
import ModuleList from "../../components/module-list/ModuleList"
import { BaseModule, DownloadOverlay, HeaderStyle, PageReference, PageThemeType, SanityCallToAction, SEOData, Slug } from "src/sanity/types"
import { useEffect } from "react"
import { useOverwrites } from "../../layout/page/overwriteContext"

export interface FrontpageDocument {
    id: string
    title: string
    slug: Slug
    description: string
    imageUrl: string
    body: string
    seo: SEOData
    headerStyle: HeaderStyle
    pageThemeType?: PageThemeType
    pageThemeColor?: string
    headerCTAOverwrite: SanityCallToAction
    modules: BaseModule[]
    refs: PageReference[]
    downloadOverlay: DownloadOverlay
    updatedAt?: string
}

const Template: NextPage<{ pageData: FrontpageDocument }> = ({ pageData } ) => {
    const { headerDownloadCTA, setDownloadOverlay, resetDownloadOverlay } = useOverwrites()

    useEffect(() => {
        if (pageData.headerCTAOverwrite?.style) {
            headerDownloadCTA.setCTA(pageData.headerCTAOverwrite)
        } else {
            headerDownloadCTA.resetCTA()
        }
    }, [])

    useEffect(() => {
        if (pageData.downloadOverlay) {
            setDownloadOverlay(pageData.downloadOverlay)
        } else {
            resetDownloadOverlay()
        }
    }, [pageData.downloadOverlay, setDownloadOverlay, resetDownloadOverlay])

    return (
        <>
            <ModuleList modules={pageData?.modules} updatedAt={pageData?.updatedAt} />
            <script type='application/ld+json'
                dangerouslySetInnerHTML={{ __html: JSON.stringify({
                    "@context":"https://schema.org",
                    "@type":"Corporation",
                    "legalName": "Lunar",
                    "description": "Lunar is a digital bank that offers a mobile-based banking app that helps consumers manage their personal finances.",
                    "url": "https://www.lunar.app",
                    "logo": "https://www.lunar.app/Lunar-logo.svg",
                    "sameAs": [
                        "https://da.wikipedia.org/wiki/Lunar",
                        "https://www.crunchbase.com/organization/lunar-bank",
                        "https://www.instagram.com/lunar/",
                        "https://www.facebook.com/lunarDanmark/",
                        "https://www.linkedin.com/company/lunarbank",
                        "https://www.trustpilot.com/review/lunar.app",
                    ],
                }) }} >
            </script>
        </>
    )
}

export default Template
